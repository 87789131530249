<template>
    <editor-sidebar class="properties-block" floating has-tabs :visible="visible" :no-slide="noSlide">
        <template v-if="!addChildMode && block" #header>
            <h4 class="sidebar-title text-uppercase mr-auto cursor-text" @click="renameBlock">{{block.displayName|tr}}</h4>
            <b-dropdown v-if="block.type !== 'footer' && block.type !== 'menu' && !readonly" no-caret right variant="light" class="mr-3">
                <template #button-content>
                    <i class="fas fa-ellipsis-h"/>
                </template>
                <template v-if="!isChild">
                    <b-dropdown-item @click="$emit('move-up', block)" :disabled="index === 0">
                        <i class="fas fa-arrow-up"/>
                        <span v-tr>Move Up|Monter</span>
                        <span class="shortcut">Ctrl + Shift + ↑</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="$emit('move-down', block)" :disabled="index === count - 1">
                        <i class="fas fa-arrow-down"/>
                        <span v-tr>Move Down|Descendre</span>
                        <span class="shortcut">Ctrl + Shift + ↓</span>
                    </b-dropdown-item>
                    <b-dropdown-divider/>
                </template>
                <b-dropdown-item @click="$emit('duplicate', block)">
                    <i class="fas fa-copy"/>
                    <span v-tr>Duplicate|Dupliquer</span>
                    <span class="shortcut">Ctrl + D</span>
                </b-dropdown-item>
                <b-dropdown-divider/>
                <e-button-confirm dropdown-item dropdown-class="text-danger" @click="$emit('delete', block)"
                                  confirm-title="Delete?|Supprimer?" button-text="Delete|Supprimer" :icon="getPublicIcon('trash')" :title="'Delete|Supprimer'|tr" v-b-tooltip>
                    <i class="fas fa-trash"/>
                    <span v-tr>Delete|Supprimer</span>
                    <span class="shortcut">Ctrl + Delete</span>
                </e-button-confirm>
            </b-dropdown>
            <b-btn variant="primary" @click="close">
                <span v-tr>Done|Valider</span>
            </b-btn>
        </template>

        <template v-if="block">
            <properties-new-block child-only :visible="addChildMode" @create-block="createChild" :child-class="block.childClass" @close="addChildMode = false;"/>

            <div v-if="blockSelection.length > 1" class="breadcrumb selection">
                <span v-for="(block, index) in blockSelection" :key="`selection-${block.id}`">
                    <i v-if="index > 0" class="fas fa-caret-right"/>
                    <b-btn @click="selectBlock(block)" size="xs" variant="white">{{block.displayName|tr}}</b-btn>
                </span>
            </div>

            <b-tabs class="tabs-pills">
                <b-tab :title="'Content|Contenu'|tr" class="padding-responsive-btn-space">
                    <template v-if="block.typeDef.isParent">
                        <h4><span v-tr>Content|Contenu</span></h4>
                        <b-card class="mb-3" no-body>
                            <vuedraggable v-model="block.children" class="list-group" handle=".drag-handle"
                                          ghost-class="list-item-ghost" drag-class="list-item-drag" animation="150" @start="drag = true">
                                <block-list-view-item v-for="child in block.children" :block="child" :key="'properties-' + child.id" :readonly="block.childrenReadonly"
                                                      @click="$emit('block-selected', child)" @duplicate="block.duplicateChild(child)" @delete="block.deleteChild(child)"/>
                            </vuedraggable>
                        </b-card>

                        <b-btn v-if="!block.childrenReadonly" variant="primary" block class="mb-3" @click="addChild()" :disabled="block.children.length >= block.maxChildren">
                            <template v-if="block.children.length >= block.maxChildren">
                                <span v-tr>Add (Limit Reached)|Ajouter (Limite atteinte)</span>
                            </template>
                            <template v-else>
                                <i class="fas fa-plus mr-1"/>
                                <span v-tr>Add Content|Ajouter contenu</span>
                            </template>
                        </b-btn>
                    </template>

                    <slot></slot>

                    <sidebar-group title="Layout|Disposition">
                        <b-form-group v-if="block.layoutPresets.length > 0">
                            <btn-responsive-prop v-model="block" field="layoutPresets" no-label/>
                            <btn-layout-preset v-for="layout in block.mediaProxy.layoutPresets" :key="`layout-${layout.id}`" v-model="block.mediaProxy.layout" :type="block.type" :layout="layout"/>
                        </b-form-group>

                        <b-form-group>
                            <btn-responsive-prop v-model="block" field="align" no-label/>
                            <btn-layout-align v-model="block.mediaProxy.align" align="inherit"/>
                            <btn-layout-align v-model="block.mediaProxy.align" align="left"/>
                            <btn-layout-align v-model="block.mediaProxy.align" align="center"/>
                            <btn-layout-align v-model="block.mediaProxy.align" align="right"/>
                        </b-form-group>

                        <slot name="layout"></slot>
                    </sidebar-group>

                    <sidebar-group title="Color Scheme|Palette de couleur">
                        <b-form-group>
                            <btn-responsive-prop v-model="block" field="colorScheme" no-label/>
                            <div>
                                <btn-color-scheme v-for="scheme in website.design.colorSchemes" :key="`scheme-${scheme.id}`" v-model="block.mediaProxy.colorScheme" :color-scheme="scheme"/>
                            </div>
                        </b-form-group>
                    </sidebar-group>

                    <sidebar-group title="Background|Arrière-plan">
                        <b-form-group label-cols="4">
                            <template #label>
                                <span>Type</span>
                                <btn-responsive-prop v-model="block" field="background"/>
                            </template>
                            <b-select v-model="block.background">
                                <option value="none" v-tr>Default|Défaut</option>
                                <option value="image" v-tr>Image</option>
                                <option value="color" v-tr>Color|Couleur</option>
                            </b-select>
                        </b-form-group>

                        <b-form-group v-if="block.background === 'image'">
                            <input-image v-model="block.backgroundImage"/>
                        </b-form-group>

                        <b-form-group v-else-if="block.background === 'color'">
                            <input-color v-model="block.backgroundColor" allow-transparent button block :label="'Custom Color|Couleur personnalisée'"/>
                        </b-form-group>

                        <b-form-group>
                            <input-border-radius v-model="block.backgroundRadius">
                                <template #prepend>
                                    <btn-responsive-prop v-model="block" field="backgroundRadius" no-label/>
                                </template>
                            </input-border-radius>
                        </b-form-group>
                    </sidebar-group>

                    <properties-component-border title="Borders|Bords" :value="block.borders"/>
                    <properties-component-shadow title="Shadow|Ombre" :value="block.shadow"/>
                </b-tab>
                <b-tab :title="'CMS|CMS'|tr" v-if="block.type !== 'footer' && block.type !== 'menu'" class="padding-responsive-btn-space">
                    <sidebar-group title="CMS Integration|Intégration CMS">
                        <b-form-group>
                            <b-checkbox switch v-model="block.cmsEnabled">
                                <span v-tr>Link to a CMS Item|Lier à un élément du CMS</span>
                            </b-checkbox>
                        </b-form-group>
                        <b-form-group v-if="block.cmsEnabled">
                            <select-cms-item v-model="block.itemId" @input="cmsItemSelected" use-store/>
                        </b-form-group>
                        <div class="form-help mb-2" v-tr>You can link this item to any CMS Item|Vous pouvez lier cet élément à n'importe quel élément du CMS</div>
                    </sidebar-group>
                    <span v-if="block.item && !isLive()">
                        {{block.item}}
                    </span>
                    <slot name="cms"></slot>
                </b-tab>
                <b-tab :title="'Advanced|Avancé'|tr" v-if="block.type !== 'menu'" class="padding-responsive-btn-space">
                    <sidebar-group title="Device Display|Affichage par appareil">
                        <b-form-group>
                            <btn-checkbox v-model="block.mediaQuery.desktop.visible" :title="'Select if visible on desktop device|Visibilité sur ordinateur'|tr">
                                <i class="fas fa-desktop" style="font-size: 16pt;"/>
                            </btn-checkbox>
                            <btn-checkbox v-model="block.mediaQuery.tablet.visible" :title="'Select if visible on tablet device|Visibilité sur tablette'|tr">
                                <i class="fas fa-tablet-screen" style="font-size: 16pt;"/>
                            </btn-checkbox>
                            <btn-checkbox v-model="block.mediaQuery.mobile.visible" :title="'Select if visible on mobile device|Visibilité sur mobile'|tr">
                                <i class="fas fa-mobile-iphone" style="font-size: 16pt;"/>
                            </btn-checkbox>
                            <btn-checkbox v-model="block.mediaQuery.kiosk.visible" :title="'Select if visible on kiosk device|Visibilité sur kiosque'|tr">
                                <i class="fak fa-kiosk" style="font-size: 16pt;"/>
                            </btn-checkbox>
                            <btn-checkbox v-model="block.mediaQuery.wheelchair.visible" :title="'Select if visible on kiosk with wheelchair mode|Visibilité sur kiosque en mode handicapé'|tr">
                                <i class="fas fa-wheelchair" style="font-size: 16pt;"/>
                            </btn-checkbox>
                        </b-form-group>
                        <div class="form-help mb-2" v-tr>You can show or hide sections based on the user device|Vous pouvez afficher ou masquer des sections en fonction de l'appareil utilisé</div>
                    </sidebar-group>
                    <sidebar-group v-if="canAdminEyeInWireless()" title="Eye-In Settings|Config Eye-In">
                        <b-form-group label="Custom ID|ID personnalisé">
                            <b-input-group>
                                <b-btn @click="toggleCustomId" :variant="block.hasCustomId() ? 'secondary': 'white'">
                                    <i class="fas " :class="block.hasCustomId() ? 'fa-lock' : 'fa-unlock'"/>
                                </b-btn>
                                <e-input v-if="!block.hasCustomId()" v-model="block.id" readonly/>
                                <e-input v-else v-model="block.customId"/>
                            </b-input-group>
                            <div class="form-help mt-2" v-tr>Custom ID can be used for CSS and Tests|L'ID peut être utilisé pour du CSS et des tests</div>
                        </b-form-group>
                    </sidebar-group>
                    <slot name="advanced"></slot>
                </b-tab>
            </b-tabs>
        </template>
    </editor-sidebar>
</template>

<script>

import BlockListViewItem from "@/components/editor/properties/block-list-view-item.vue";
import BtnResponsiveProp from "@/components/editor/properties/components/btn-responsive-prop.vue";
import PropertiesComponentBorder from "@/components/editor/properties/components/properties-component-border.vue";
import PropertiesComponentShadow from "@/components/editor/properties/components/properties-component-shadow.vue";
import SidebarGroup from "@/components/editor/properties/components/sidebar-group.vue";
import BtnCheckbox from "@/components/editor/properties/inputs/btn-checkbox.vue";
import InputBorderRadius from "@/components/editor/properties/inputs/input-border-radius.vue";
import InputColor from "@/components/editor/properties/inputs/input-color.vue";
import InputImage from "@/components/editor/properties/inputs/input-image.vue";
import BtnColorScheme from "@/components/editor/properties/inputs/btn-color-scheme.vue";
import BtnLayoutAlign from "@/components/editor/properties/inputs/btn-layout-align.vue";
import BtnLayoutPreset from "@/components/editor/properties/inputs/btn-layout-preset.vue";
import SelectCmsItem from "@/components/editor/properties/inputs/select-cms-item.vue";
import PropertiesNewBlock from "@/components/editor/properties/properties-new-block.vue";

// properties-block #hot-reload-debug
import EditorSidebar from "@/layout/editor-sidebar.vue";
import Vuedraggable from "vuedraggable";
import EButtonConfirm from "../../../../../vue-components/components/e-button-confirm.vue";
import EInput from "../../../../../vue-components/components/e-input.vue";

export default {
    name: `properties-block`,
    components: {
        EInput, PropertiesComponentShadow, BtnResponsiveProp, BtnCheckbox, SelectCmsItem, InputBorderRadius, SidebarGroup, EButtonConfirm,
        BtnLayoutAlign, PropertiesComponentBorder, BtnLayoutPreset, PropertiesNewBlock, BtnColorScheme, InputColor, Vuedraggable, InputImage, BlockListViewItem, EditorSidebar},
    props: {
        value: {type: Object, required: true},
        blockSelection: {type: Array},
        isChild: {type: Boolean},
        readonly: {type: Boolean},
        noSlide: {type: Boolean},
        item: {type: Object},
        index: {type: Number},
        count: {type: Number}
    },
    data() {
        return {
            addChildMode: false,
            drag: false,
            visible: false
        }
    },
    computed: {
        componentProps() {
            return {
                block: this.block,
                boundItem: this.boundItem
            }
        },
        boundItem() {
            if (this.item) {
                return this.item;
            }
            if (this.block.cmsEnabled && this.block.item) {
                return this.block.item;
            } else if (this.cmsPage && this.block.itemType === `inherit`) {
                return this.cmsPage.item;
            } else {
                return this.block.item;
            }
        },
        boundItemList() {
            if (this.block.itemType === `inherit`) {
                return this.cms.getItemsByType(this.cmsPage.itemType);
                // return this.cmsPage.itemList;
            } else {
                return this.block.itemList;
            }
        },
        block: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    },
    mounted() {
        // to trigger slide in animation
        setTimeout(() => {
            this.visible = true;
        }, 50)
    },
    methods: {
        addChild() {
            if (this.block.childClass.length === 1) {
                this.createChild(this.block.childClass[0]);
            } else {
                this.addChildMode = true
            }
        },
        createChild(preset) {
            const block = new preset();
            this.block.addChild(block);
            this.addChildMode = false;
        },
        close() {
            this.visible = false;
            // to let slide out animation before hiding
            if (this.noSlide) {
                this.$emit(`close`);
            } else {
                setTimeout(_ => {
                    this.$emit(`close`);
                }, 250)
            }
        },
        cmsItemSelected(itemId) {
            this.$set(this.block, `item`, this.cms.getItemById(itemId));
            if (this.block.item) {
                this.$set(this.block, `itemType`, this.block.item.type);
            } else if (this.block.item) {
                this.$set(this.block, `itemType`, null);
            }
        },
        selectBlock(block) {
            this.$emit(`block-selected`, block);
        },
        renameBlock() {
            this.showRenamePrompt(`Rename|Renommer`, `Enter the new name of this section. Leave blank to use default name.|Entrez le nouveau nom pour cette section. Laissez vide pour utiliser le nom par défaut.`, this.block.name || ``, `Rename|Renommer`, undefined, name => {
                this.block.name = name;
            });
        },
        toggleCustomId() {
            if (!this.block.hasCustomId()) {
                this.$set(this.block, `customId`, this.block.id);
            } else {
                this.$set(this.block, `customId`, null);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
    background: var(--lightest-color);
    border-top: 1px solid var(--border-color);
    border-radius: 0;
    padding: 3px;
    margin-bottom: 0;
    font-size: 9pt;
    line-height: 26px;

    .btn {
        border-radius: 3px;
        padding: 3px 6px;
        font-size: inherit;
    }

    i {
        vertical-align: middle;
        margin: 0 2px;
    }
}

.padding-responsive-btn-space {
    padding: 1rem 0.5rem 1rem 1.5rem;
}

</style>
